@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/c68a8a6e82ca2361-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: italic;
}

@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/87978b877048b6ce-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/8f9c64b3039176dd-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: italic;
}

@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/52494043d960c4e5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/bfca15ec9a087c74-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'aeonik';
src: url(/_next/static/media/71f3735817dbe64b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
font-style: normal;
}@font-face {font-family: 'aeonik Fallback';src: local("Arial");ascent-override: 91.72%;descent-override: 20.71%;line-gap-override: 0.00%;size-adjust: 101.40%
}.__className_1fc149 {font-family: 'aeonik', 'aeonik Fallback', helvetica, arial, sans-serif
}

